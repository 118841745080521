import Vue from 'vue';
import Vuex from "vuex";
import * as moment from 'moment';
import 'moment/locale/de';
import store from  '../store/index';

Vue.use(Vuex);

Vue.filter('formatDate', function (value, format) {
  if(value) {
    return moment(value).format(format);
  }
  return '';
});

Vue.filter('dataTrans', function (value, base) {
  let dataBase = Object.assign({}, store.getters.settings);
  base.split('.').forEach((item) => {
    dataBase = dataBase[item];
  });
  console.log(dataBase);
  return dataBase[value];
});
