export default {
  mapConstraints(data) {
    let errors = {};

    if ('violations' in data) {
      console.log(data);
      data.violations.forEach((item) => {
        console.log(item.propertyPath);
        if (item.propertyPath.indexOf('.') >= 0) {
          let [subProperty, path] = item.propertyPath.split('.');
          if (typeof errors[subProperty] === 'undefined') {
            errors[subProperty] = [];
          }

          if (typeof errors[subProperty][path] === 'undefined') {
            errors[subProperty][path] = [item.message];
          }
        } else if (item.propertyPath.indexOf('.') < 0) {
          if (typeof errors[item.propertyPath] === 'undefined') {
            errors[item.propertyPath] = [];
            errors[item.propertyPath].push(item.message);
          } else {
            errors[item.propertyPath].push(item.message);
          }
        }
      });
    }

    console.log(JSON.stringify(errors));

    return errors;
  }
}
