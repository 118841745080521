import Vue from 'vue';
import Vuex from 'vuex';
import VueResource from 'vue-resource';

Vue.use(Vuex);
Vue.use(VueResource);

export default new Vuex.Store({
  state: {
    currentUser: {},
    environment: {},
    settings: {},
    loadingIndex: 0,
  },
  getters: {
    /*getEnvironment(state, getters) {
        return state.environment;
    },
    getLoggedInUser(state, getters) {
        return state.loggedInUser;
    },
    hasLoggedInUserCredibility: (state) => (credibility) => {
        return state.loggedInUser.credibilitySlugs.indexOf(credibility) > -1;
    },
    */
    getLoadingIndex(state) {
      return state.loadingIndex;
    },
    settings: state => {
      return state.settings;
    },
    currentUser: state => {
      return state.currentUser;
    }
  },
  mutations: {
    setSettings(state, settings) {
      state.settings = settings;
    },
    setEnvironment(state, env) {
      state.environment = env;
    },
    setCurrentUser(state, currentUser) {
      state.currentUser = currentUser;
    },
    incrementLoadingIndex(state) {
      state.loadingIndex++;
    },
    decrementLoadingIndex(state) {
      state.loadingIndex--;
    }
  },
  actions: {
    getEnvironment(context) {
      return context.commit('setEnvironment', process.env.NODE_ENV);
    },
    reloadLoggedInUser(context) {
      Vue.http.get('current_user').then(response => {
        context.commit('setLoggedInUser', response.data);
        return context.loggedInUser;
      }, () => {
        context.commit('setLoggedInUser', null);
        return null;
      });
    },
    getLoggedInUser(context) {
      if (context.loggedInUser) {
        return context.loggedInUser;
      } else {
        Vue.http.get('current_user').then(response => {
          context.commit('setLoggedInUser', response.data);
          return context.loggedInUser;
        }, () => {
          context.commit('setLoggedInUser', null);
          return null;
        });
      }
    }
  }
});
