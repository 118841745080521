import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Security',
    component: () => import('../views/Security.vue'),
    children: [
      {
        path: 'login',
        component: () => import('../views/Security/Login.vue'),
      },
      {
        path: 'password-reset/:token',
        component: () => import('../views/Security/PasswordReset.vue'),
      },
      {
        path: 'password-forget',
        component: () => import('../views/Security/PasswordForget.vue'),
      },
      {
        path: 'logout',
        component: () => import('../views/Security/Logout.vue'),
      },
    ],
  },
  {
    path: '/',
    name: 'Application',
    component: () => import('../views/Application.vue'),
    children: [
      {
        path: 'api-keys',
        component: () => import('../views/Application/ApiKeys.vue'),
        meta: {
          headline: 'API-Schlüssel / Integrationen'
        }
      },
      {
        path: 'api-keys/:id',
        component: () => import('../views/Application/ApiKey.vue'),
        meta: {
          headline: 'API-Schlüssel / Integrationen'
        }
      },
      {
        path: 'companies',
        component: () => import('../views/Application/Companies.vue'),
      },
      {
        path: 'companies/:id',
        component: () => import('../views/Application/Company.vue'),
      },
      {
        path: 'surveys',
        component: () => import('../views/Application/Surveys.vue'),
      },
      {
        path: 'surveys/create',
        component: () => import('../views/Application/SurveyCreate.vue'),
      },
      {
        path: 'surveys/:id',
        component: () => import('../views/Application/Survey.vue'),
        children: [
          {
            name: 'BusinessData',
            path: 'business-data',
            component: () => import('../views/Application/Survey/BusinessData')
          },
          {
            name: 'HumanResource',
            path: 'human-resource',
            component: () => import('../views/Application/Survey/HumanResource')
          },
          {
            name: 'Invest',
            path: 'invest',
            component: () => import('../views/Application/Survey/Invest')
          },
          {
            name: 'Structure',
            path: 'structure',
            component: () => import('../views/Application/Survey/Structure')
          },
          {
            name: 'Audits',
            path: 'audits',
            component: () => import('../views/Application/Survey/Audits')
          },
          {
            name: 'Finish',
            path: 'finish',
            component: () => import('../views/Application/Survey/Finish')
          },
        ]
      },
      {
        path: 'users',
        component: () => import('../views/Application/Users.vue'),
        meta: {
          headline: 'Benutzerverwaltung'
        }
      },
      {
        path: 'users/:id',
        component: () => import('../views/Application/User.vue'),
        meta: {
          headline: 'Benutzerverwaltung'
        }
      },
      {
        path: 'version-and-status',
        component: () => import('../views/Application/VersionAndStatus.vue'),
        meta: {
          headline: 'Entwicklung'
        }
      },
      {
        path: 'token',
        component: () => import('../views/Application/Token.vue'),
        meta: {
          headline: 'Entwicklung'
        }
      },
      {
        path: '',
        redirect: to => {
          return {
            path: '/surveys'
          }
        }
      },
      {
        path: '*',
        component: () => import('../views/Application/404.vue'),
      },
    ],
  }
]

const router = new VueRouter({
  routes
})

export default router
