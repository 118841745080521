import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './assets/scss/main.scss';
import en from './lang/en'
import de from './lang/de'

const messages = {
  en,
  de
}
import VueI18n from 'vue-i18n';
Vue.use(VueI18n)
const i18n = new VueI18n({
  locale: 'de',
  messages
})

/* VueX */
import Vuex from 'vuex'

Vue.use(Vuex);
import store from "./store";

/* BrowserDetect */
import browserDetect from "vue-browser-detect-plugin";

Vue.use(browserDetect);

import Vuelidate from 'vuelidate';

Vue.use(Vuelidate);

/* PrimeVue */
import PrimeVue from 'primevue/config';
import ToastService from 'primevue/toastservice';
import ConfirmationService from 'primevue/confirmationservice';
import Tooltip from 'primevue/tooltip';

Vue.use(PrimeVue);
Vue.use(ToastService);
Vue.use(ConfirmationService);
Vue.directive('tooltip', Tooltip);

/* Extra functionality */
require('./plugins/http');
require('./plugins/api');
require('./plugins/lang');
require('./filter');

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App),
  store: store,
  i18n: i18n,
}).$mount('#app')
