<template>
    <div>
        <div class="flex lg:hidden h-screen w-screen align-items-center align-content-center flex-wrap flex-1 p-4">
            <div class="block w-full">
                <div class="m-4 p-4 text-center bg-yellow-300 border-1 border-yellow-800 text-yellow-800">
                    {{$t('The application is not available on mobile devices.')}}
                </div>
            </div>
        </div>
        <div class="hidden lg:block">
            <Toast position="top-right"/>
            <router-view/>
        </div>
    </div>
</template>

<script>
    import Toast from 'primevue/toast';

    export default {
        components: {
            Toast
        }
    }
</script>
