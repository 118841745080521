import Vue from 'vue';
import VueResource from 'vue-resource';
import util from './util';

Vue.use(VueResource);

Vue.use({
    install(Vue) {
      Vue.prototype.$sendToApi = function (baseApiUrl, entity, callback, updateMethod = 'put') {
        let method = entity.hasOwnProperty('id') ? updateMethod : 'post';
        let url = entity.hasOwnProperty('id') ? baseApiUrl + '/' + entity.id : baseApiUrl;

        let objectToSend = Object.assign({}, entity);
        Object.keys(objectToSend).forEach((item) => {
          if (
            typeof objectToSend[item] !== 'undefined'
            && objectToSend[item] !== null
            && objectToSend[item].hasOwnProperty('@id')
          ) {
            objectToSend[item] = objectToSend[item]['@id'];
          }

          if(Array.isArray(objectToSend[item])) {
            Object.keys(objectToSend[item]).forEach((arrayItem) => {
              if(typeof objectToSend[item] !== 'undefined' && objectToSend[item][arrayItem].hasOwnProperty('@id')) {
                objectToSend[item][arrayItem] = objectToSend[item][arrayItem]['@id'];
              }
            });
          }
        });

        this.$store.commit('incrementLoadingIndex');
        this.$http[method](url, objectToSend).then(response => {
          this.$store.commit('decrementLoadingIndex');
          this.$toast.add({
            summary: this.$t('Success'),
            severity: 'success',
            detail: this.$t('Successfully saved'),
            life: 3000
          });
          entity = response.data;
          callback(entity, parseInt(response.status), {});
        }, response => {
          this.$store.commit('decrementLoadingIndex');
          this.$toast.add({
            life: 3000,
            summary: response.status < 500 ? this.$t('Warn') : this.$t('Error'),
            severity: response.status < 500 ? 'warn' : 'error',
            detail: parseInt(response.status) === 403 ? this.$t('Your user is not authorized to edit the record') : this.$t('Error while saving')
          });
          callback(entity, parseInt(response.status), response.status !== 500 ? util.mapConstraints(response.data) : []);
        });
      };

      Vue.prototype.$getFromApiSearch = function (baseApiUrl, params, callback) {
        this.$store.commit('incrementLoadingIndex');
        this.$http['get'](baseApiUrl, {params: params}).then(response => {
          this.$store.commit('decrementLoadingIndex');
          if (response.data['hydra:member'].length > 0) {
            callback(response.data['hydra:member'][0]);
          }
        }, response => {
          this.$store.commit('decrementLoadingIndex');
          this.$toast.add({
            life: 3000,
            summary: response.status < 500 ? this.$t('Warn') : this.$t('Error'),
            severity: response.status < 500 ? 'warn' : 'error',
            detail: this.$t('Error while loading data')
          });
          callback(null);
        });
      }

      Vue.prototype.$getFromApi = function (baseApiUrl, entity, callback) {
        let url = baseApiUrl + '/' + (typeof entity.id !== 'undefined' ? entity.id : entity);

        this.$store.commit('incrementLoadingIndex');
        this.$http['get'](url).then(response => {
          this.$store.commit('decrementLoadingIndex');
          entity = response.data;
          callback(entity);
        }, response => {
          this.$store.commit('decrementLoadingIndex');
          this.$toast.add({
            severity: response.status < 500 ? 'warn' : 'error',
            detail: this.$t('Error while loading data')
          });
          callback(entity);
        });
      };

      Vue.prototype.$deleteFromApi = function (baseApiUrl, entity, callback) {
        let url = baseApiUrl + '/' + (typeof entity.id !== 'undefined' ? entity.id : entity);

        this.$store.commit('incrementLoadingIndex');
        this.$http['delete'](url).then(response => {
          this.$store.commit('decrementLoadingIndex');
          entity = response.data;
          callback(null);
        }, () => {
          this.$store.commit('decrementLoadingIndex');
          this.$toast.add({
            severity: 'warn',
            detail: this.$t('Error while deleting data')
          });
          callback(entity);
        });
      };
    }
  }
)

